body {
  background-color: #001219;
  font-family: Calibre, Inter, "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
  color: #f7ede2;
}

::selection {
  background: #e63946;
}

#body-content {
  width: 100%;
  padding: 0px 150px;
  position: absolute;
  z-index: 1;
}

.sections {
  margin: 0px auto;
  padding: 100px 0px;
}

.section-heading {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0px 40px;
  width: 100%;
  font-size: clamp(26px, 5vw, 32px);
  white-space: nowrap;
  color: #ddb892;
}

.section-heading::after {
  content: "";
  display: block;
  position: relative;
  width: 300px;
  height: 1px;
  margin-left: 20px;
  background-color: #ddb892;
}

.btn {
  color: #ddb892;
  border-color: #ddb892;
  margin: 0 1.5rem;
  padding: 8px;
}

.btn:hover {
  color: #ddb892;
  background-color: #f7ede2;
  border-color: #f7ede2;
}

.link {
  color: #ddb892 !important;
}

.link:hover {
  color: #f7ede2 !important;
}

.link-icon {
  filter: invert(86%) sepia(37%) saturate(262%) hue-rotate(0deg) brightness(96%)
    contrast(91%);
}

.link-icon:hover {
  filter: invert(100%) sepia(0%) saturate(3054%) hue-rotate(339deg)
    brightness(120%) contrast(86%);
}

/* Screen width 991px max */
@media screen and (max-width: 991px) {
  #body-content {
    padding: 0px 50px;
  }
}

/* Screen width 768px max */
@media screen and (max-width: 768px) {
  .col {
    flex: none;
  }
}

/* Screen width 500px max */
@media screen and (max-width: 500px) {
  #body-content {
    padding: 0px 20px;
  }
}

/* Screen width 300px max */
@media screen and (max-width: 375px) {
  #body-content {
    padding: 0px 10px;
    width: 340px;
  }

  .col {
    padding: 0px;
  }
}
