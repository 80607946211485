/* Social media for left side */
.content-container-left {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: 20px;
  right: auto;
  z-index: 3;
}

/* Add vertical line after icons */
.content-container-left::after {
  content: "";
  display: block;
  width: 2px;
  height: 100px;
  margin: 0px auto;
  background-color: #ddb892;
}

.social-content-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.social-content-left li:last-of-type {
  margin-bottom: 20px;
}

.content-container-button {
  display: none !important;
}

/* Social media for footer */
.content-container-button {
  display: block;
  width: 100%;
  max-width: 270px;
  margin: 0px auto 10px;
}

.social-content-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

/* Screen width 991px max */
@media screen and (max-width: 991px) {
  .content-container-left {
    display: none;
  }

  .content-container-button {
    display: block !important;
  }
}
